import React from "react";
import { Link } from "react-router-dom";
import { Avatar as ChakraAvatar, Spinner } from "@chakra-ui/react";
import { PROTECTED } from "lib/routes";

export default function Avatar({ user, size = "lg", overrideAvatar = null }) {
  if (!user)
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    );
  return (
    <ChakraAvatar
      as={Link}
      to={`${PROTECTED}/profile/${user?.id}`}
      name={user.username}
      size={size}
      src={overrideAvatar || user.avatar}
      _hover={{ cursor: "pointer", opacity: "0.8" }}
    />
  );
}
